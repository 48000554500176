.sessao-site {
  width: 100%;
  max-width: 100%;
}
.sessao-1 {
  color: #2e302e;
  background-color: transparent;
  .titulo {
    font-size: 4.5rem;
    font-weight: bold;
  }
  .texto-secundario {
    font-size: 1.5rem;
  }
  @media (max-width: 1000px) {
    .titulo {
      font-size: 3rem;
    }
    .texto-secundario {
      font-size: 1.6rem;
    }
  }
  @media (max-width: 600px) {
    .titulo {
      font-size: 2rem;
    }
    .texto-secundario {
      font-size: 1.4rem;
    }
  }
  .banner {
    max-width: 100%;
  }
}

.sessao-2 {
  color: #2e302e;
  background-color: #1ca4bf0f;
  padding-top: 2rem;
  padding-bottom: 1rem;
  .titulo {
    font-size: 2rem;
    font-weight: bold;
  }
  .subtitulo {
    font-size: 1.3rem;
  }

  .botao {
    color: white;
  }
  .imagem-sessao-2 {
    width: 80%;
    height: auto;
    margin: auto;
  }

  .descricao {
    padding-top: 0.3rem;
    font-size: 1rem;
  }
}
#panel1a-header,
#panel2a-header {
  border-radius: 10px;
}
.sessao-3 {
  padding: 4rem;

  .titulo-facilidades {
    font-size: 2rem;
  }

  .subtitulo {
    font-size: 1.2rem;
  }
}

.sessao-4 {
  padding: 4rem;

  .formas-pagamentos {
    border: 3px solid #2a1a82;
    border-radius: 2rem;
    padding: 4rem;
    align-items: center;

    .opcao-pagamento {
      font-size: large;
    }

    @media (max-width: 600px) {
      padding: 0;
    }
  }

  @media (max-width: 600px) {
    padding: 2rem;
    margin-left: 10px;
  }
}

.como-funciona-items {
  margin: 1rem 0;
  border-radius: 10px;

  .descricao {
    font-size: 1rem;
  }
}

.color-movipay {
  color: #2a1a82;
  font-weight: bold;
}

.destaque {
  background-color: #ffce00;
  color: #fff;
  padding: 0.5rem;
  border-radius: 10px;

  @media (max-width: 600px) {
    padding: 0.3rem;
  }
}

.botao-abrir-conta {
  padding: 12px 40px;
  font-size: 1.2rem;
  color: #f1f2f2;
  background-color: #1e294c;

  &:hover {
    background-color: #1e294c;
  }
}
