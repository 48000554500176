.sessao-rodape {
  background-color: #2e302e;
  color: #e4faec;
  padding-top: 2rem;
  .contato {
    color: #ffce00;
    font-size: 1.1rem;
  }
  .endereco {
    font-size: 1.1rem;
  }
  .imagem {
    margin-bottom: 1rem;
    img {
      max-width: 200px;
    }
  }
  .redes-sociais {
    margin: 1rem 0;
    height: 50px;
    cursor: pointer;
  }
  .termos-uso {
    .barra {
      color: gray;
    }
    p {
      padding: 3px 6px;
      cursor: pointer;
    }
  }
  .sessao-selos {
    display: flex;
    margin-bottom: 5px;
    .selos {
      margin: auto;
      background-color: white;
      border-radius: 10px;
      display: flex;
      padding-left: 2rem;
      padding-right: 2rem;
      .selo {
        img {
          max-height: 60px;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .sessao-rodape {
    .sessao-selos {
      .selo {
        img {
          max-height: 30px !important;
        }
      }
    }
  }
}

@media (max-width: 300px) {
  .sessao-rodape {
    .sessao-selos {
      .selo {
        img {
          display: none !important;
        }
      }
    }
  }
}
