
.sessao-sobre{
    width: 100%;
    max-width: 100%;
    color: #2E302E;
    padding-top:2rem;
    padding-bottom: 2rem;

    .descricao{
        font-size: 1rem;
    }
    .imagem{
        img{
            width: 100%;
        }
    }
    .botao{
        color: white;
    }
}