.movi {
  color: #ffce00;
}
.sessao-site-desenvolvedores {
  width: 100%;
  max-width: 100%;
  padding: 6rem !important;

  @media (max-width: 600px) {
    padding: 3rem !important;
  }
}

.container-section {
  padding: 24px;
  margin: auto;
  align-items: center;
}

.sessao-site-desenvolvedores:nth-child(odd) {
  color: #2e302e;
  background-color: #1ca4bf0f;
}

.sessao-site-desenvolvedores:nth-child(even) {
  color: #2e302e;
  background-color: #ffffff;
}

.botao-documentacao {
  margin-top: 1rem;
}

.logo-postman {
  cursor: pointer;
  margin-top: 0.5rem;
}

.titulo-collections {
  font-size: 2.5rem;
}

.titulo {
  font-size: 4rem !important;
  font-weight: bold;
}
.texto-secundario {
  font-size: 1.5rem !important;
  margin-top: 1rem;
}

.banner {
  width: 100%;
  border-radius: 25px;
}

@media (max-width: 1000px) {
  .banner {
    width: 100%;
  }

  .principal {
    display: none;
  }

  .texto-secundario {
    font-size: 1.6rem !important;
  }
}
@media (max-width: 600px) {
  .principal {
    display: none;
  }
  .banner {
    width: 100%;
  }

  .titulo {
    font-size: 2rem !important;
  }
  .texto-secundario {
    font-size: 1.4rem !important;
  }
}
