.toolbar {
  background-color: #fcfcfc;
  box-shadow: 0px 2px 4px -1px #00000038, 0px 1px 15px 0px #00000024,
    0px 0px 1px 0px #0000001f;
}
.botao {
  font-size: 1rem;
  font-weight: 600;
}
.botao-cadastrar {
  font-size: 1.1rem;
  color: white;
  background-color: #1e294c;

  &:hover {
    background-color: #1e294c;
  }
}
.logo-quita {
  cursor: pointer;
  max-width: 150px;
}
