
.sessao-termos-uso{
    width: 100%;
    max-width: 100%;
    color: #2E302E;
    padding-top:2rem;
    padding-bottom: 2rem;

    .titulo{
        font-size: 2rem;
        font-weight: bold;
        line-height: 35px;
    }
    .subtitulo{
        font-size: 1.6rem;
        font-weight: 600;
    }
    .descricao{
        font-size: 1rem;
        margin-bottom: 2rem;
    }
    .descricao-titulo{
        font-size: 1.2rem;
    }
    .imagem{
        img{
            width: 100%;
        }
    }
    .botao{
        color: white;
    }
    .contato{
        img{
            width: 60px;
        }
        .contato-texto{
            font-size: 1.2rem;
        }
    }
}